import React, { useReducer } from "react";
import clsx from "clsx";
import {
    makeStyles,
    useTheme,
    Drawer,
    AppBar,
    Toolbar,
    List,
    CssBaseline,
    Typography,
    Divider,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";
import logo from "../assets/logo.svg";
import navData from "../static/navdata";
import { Menu, ChevronLeft, ChevronRight } from "@material-ui/icons";
import { initialState, reducer } from "./hooks/globalReducer";
import { GlobalContext } from "./hooks/globalContext";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        backgroundColor: "white",
        color: "black",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    navLogo: {
        width: "150px",
        float: "left",
    },
    shown: {
        display: "block",
    },
    hidden: {
        display: "none",
    },
}));

export default function BaseLayout() {
    const classes = useStyles();
    const theme = useTheme();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [open, setOpen] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState(0);
    const [renderablePages, setRenderablePages] = React.useState([]);

    React.useEffect(() => {
        console.log("in baselayout");
        console.log(state);
        if (state.loggedUser.uid == "") {
            setRenderablePages([navData[0]]);
        } else {
            navData.shift();
            setRenderablePages(navData);
        }
        dispatch({ type: "setCurrentPage", payload: navData[0].text });
    }, [state.loggedUser.uid]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <GlobalContext.Provider value={[state, dispatch]}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, {
                                [classes.hide]: open,
                            })}
                        >
                            <Menu />
                        </IconButton>
                        <Typography variant="h6" noWrap>
                            {state.currentPage}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                >
                    <div className={classes.toolbar}>
                        <img src={logo} className={classes.navLogo} />
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === "rtl" ? (
                                <ChevronRight />
                            ) : (
                                <ChevronLeft />
                            )}
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        {renderablePages.map((data, id) => (
                            <ListItem
                                button
                                key={id}
                                selected={
                                    selectedItem === id &&
                                    id != navData.length - 1
                                }
                                onClick={() => {
                                    setSelectedItem(id);
                                    dispatch({
                                        type: "setCurrentPage",
                                        payload: data.text,
                                    });
                                }}
                            >
                                <ListItemIcon>{data.icon}</ListItemIcon>
                                <ListItemText primary={data.text} />
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    {renderablePages.map((data, id) => {
                        return (
                            <div
                                key={id}
                                className={
                                    selectedItem === id
                                        ? classes.shown
                                        : classes.hidden
                                }
                            >
                                {data.content}
                            </div>
                        );
                    })}
                </main>
            </div>
        </GlobalContext.Provider>
    );
}
