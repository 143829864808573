import React, { useContext } from "react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { Eventcalendar, Datepicker } from "@mobiscroll/react";
import {
    Box,
    Button,
    FormControl,
    Select,
    MenuItem,
    FormHelperText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    useMediaQuery,
    useTheme,
    InputLabel,
    TextField,
} from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { Add, Block } from "@material-ui/icons";
import axios from "axios";
import { GlobalContext } from "../hooks/globalContext";

export const Schedule = () => {
    const [state, dispatch] = useContext(GlobalContext);

    const [firstFetched, setFirstFetched] = React.useState(false);
    const [rawData, setRawData] = React.useState([]);
    const [shownAppointments, setShownAppointments] = React.useState([]);
    const [userInfo, setUserInfo] = React.useState({});
    const [pros, setPros] = React.useState([]);
    const [locs, setLocs] = React.useState([]);
    const [services, setServices] = React.useState([]);
    const [clients, setClients] = React.useState([]);

    const [selectedView, setSelectedView] = React.useState("month");
    const [selectedPro, setSelectedPro] = React.useState("All");
    const [selectedLoc, setSelectedLoc] = React.useState("All");
    const [selectedSvc, setSelectedSvc] = React.useState("All");

    const [appointmentOpened, setAppointmentOpened] = React.useState(false);
    const [appointmentSelectedPro, setAppointmentSelectedPro] =
        React.useState("");
    const [appointmentSelectedLoc, setAppointmentSelectedLoc] =
        React.useState("");
    const [appointmentSelectedSvc, setAppointmentSelectedSvc] =
        React.useState("");
    const [appointmentSelectedClt, setAppointmentSelectedClt] =
        React.useState("");
    const [appointmentTime, setAppointmentTime] = React.useState("");

    const [blockOpened, setBlockOpened] = React.useState(false);
    const [blockSelectedPro, setBlockSelectedPro] = React.useState("");
    const [blockSelectedLoc, setBlockSelectedLoc] = React.useState("");
    const [blockTime, setBlockTime] = React.useState("");

    const [invalidDatesByProfessional, setInvalidDatesByProfessional] = React.useState([]);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const dev_hosts = ["local.piebooking.dev"];
    var api_host = window.location.hostname;
    if (api_host.startsWith("react-")) api_host = api_host.slice(6);
    const is_dev = dev_hosts.filter((x) => api_host.includes(x)).length > 0;
    const api_port = is_dev ? ":5001" : "";
    const api_proto = window.location.protocol;
    const api_url = `${api_proto}//api.${api_host}${api_port}/v3`;

    let fetchAppointments = (event) => {
        console.log("fetching appointments...");
        let start = event.firstDay.toISOString();
        let end = event.lastDay.toISOString();

        const appointmentsOptions = {
            url: `${api_url}/appts?match={"after":"${start}","before":"${end}"}`,
            method: "GET",
            headers: { Authorization: "Bearer " + state.loggedUser.idt },
        };

        axios(appointmentsOptions)
            .then((response) => {
                console.log("fetched appointments...");
                console.log(response.data);
                setRawData(response.data);
                var all_data = [];

                for (var i = 0; i < response.data.length; i++) {
                    var apt_title = "";
                    if (response.data[i].svcs) {
                        apt_title +=
                            response.data[i].svcs
                                .map((s) => s.name)
                                .join(" + ") + " by ";
                    }
                    if (response.data[i].pros) {
                        apt_title += response.data[i].pros
                            .map((s) => s.callby_name)
                            .join(" & ");
                    }
                    if (response.data[i].clts) {
                        apt_title +=
                            " for " +
                            response.data[i].clts
                                .map((s) => s.callby_name)
                                .join(" / ");
                    }
                    if (response.data[i].locs) {
                        apt_title +=
                            " at " +
                            response.data[i].locs
                                .map((s) => s.name)
                                .join(" and ");
                    }
                    all_data.push({
                        start: response.data[i].start,
                        end: response.data[i].end,
                        id: response.data[i]._id,
                        title: apt_title,
                    });
                }
                setShownAppointments(all_data);
            })
            .catch((err) => console.log(err));
    };

    let fetchAllData = () => {
        console.log("fetching userinfo, pros, locs, svcs & clts...");

        const professionalsOptions = {
            url: `${api_url}/pros`,
            method: "GET",
            headers: { Authorization: "Bearer " + state.loggedUser.idt },
        };

        axios(professionalsOptions)
            .then((response) => {
                console.log("fetched professionals...");
                console.log(response.data);
                setPros(response.data);
            })
            .catch((err) => console.log(err));

        const locationsOptions = {
            url: `${api_url}/locs`,
            method: "GET",
            headers: { Authorization: "Bearer " + state.loggedUser.idt },
        };

        axios(locationsOptions)
            .then((response) => {
                console.log("fetched locations...");
                console.log(response.data);
                setLocs(response.data);
            })
            .catch((err) => console.log(err));

        const servicesOptions = {
            url: `${api_url}/svcs`,
            method: "GET",
            headers: { Authorization: "Bearer " + state.loggedUser.idt },
        };

        axios(servicesOptions)
            .then((response) => {
                console.log("fetched services...");
                console.log(response.data);
                setServices(response.data);
            })
            .catch((err) => console.log(err));

        const clientsOptions = {
            url: `${api_url}/clts`,
            method: "GET",
            headers: { Authorization: "Bearer " + state.loggedUser.idt },
        };

        axios(clientsOptions)
            .then((response) => {
                console.log("fetched clients...");
                console.log(response.data);

                const userOptions = {
                    url: `${api_url}/ping`,
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + state.loggedUser.idt,
                    },
                };

                axios(userOptions)
                    .then((response2) => {
                        console.log("fetched user info...");
                        console.log(response2.data);
                        setUserInfo(response2.data);
                        response.data.unshift({
                            _id: response2.data.token_info.uid,
                            name: "Myself",
                        });
                        setClients(response.data);
                    })
                    .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
    };

    let responsiveCalendarViews = {
        xsmall: {
            view: {
                calendar: {
                    type: selectedView,
                    size: "2",
                },
                agenda: {
                    type: "day",
                },
            },
        },
        medium: {
            view: {
                calendar: {
                    type: selectedView,
                    size: "2",
                    popover: true,
                },
            },
        },
    };

    let applyAllFilters = (filterItem, filterValue) => {
        filterItem == "pros" && setSelectedPro(filterValue);
        filterItem == "locs" && setSelectedLoc(filterValue);
        filterItem == "svcs" && setSelectedSvc(filterValue);

        var new_data = [];
        for (var i = 0; i < rawData.length; i++) {
            var apt_title = "";
            if (rawData[i].svcs) {
                apt_title +=
                    rawData[i].svcs.map((s) => s.name).join(" + ") + " by ";
            }
            if (rawData[i].pros) {
                apt_title += rawData[i].pros
                    .map((s) => s.callby_name)
                    .join(" & ");
            }
            if (rawData[i].clts) {
                apt_title +=
                    " for " +
                    rawData[i].clts.map((s) => s.callby_name).join(" / ");
            }
            if (rawData[i].locs) {
                apt_title +=
                    " at " + rawData[i].locs.map((s) => s.name).join(" and ");
            }

            var pros_match = filterItem !== "pros";
            if (filterItem == "pros") {
                if (
                    rawData[i].pros &&
                    (filterValue == "All" ||
                        rawData[i].pros
                            .map((s) => s.callby_name)
                            .includes(filterValue))
                ) {
                    pros_match = true;
                }
            }

            var svcs_match = filterItem !== "svcs";
            if (filterItem == "svcs") {
                if (
                    rawData[i].svcs &&
                    (filterValue == "All" ||
                        rawData[i].svcs
                            .map((s) => s.name)
                            .includes(filterValue))
                ) {
                    svcs_match = true;
                }
            }

            var locs_match = filterItem !== "locs";
            if (filterItem == "locs") {
                if (
                    rawData[i].locs &&
                    (filterValue == "All" ||
                        rawData[i].locs
                            .map((s) => s.name)
                            .includes(filterValue))
                ) {
                    locs_match = true;
                }
            }

            var clts_match = filterItem !== "clts";
            if (filterItem == "clts") {
                if (
                    rawData[i].clts &&
                    (filterValue == "All" ||
                        rawData[i].clts
                            .map((s) => s.callby_name)
                            .includes(filterValue))
                ) {
                    clts_match = true;
                }
            }

            if (pros_match && svcs_match && locs_match && clts_match) {
                new_data.push({
                    start: rawData[i].start,
                    end: rawData[i].end,
                    id: rawData[i]._id,
                    title: apt_title,
                });
            }
        }
        setShownAppointments(new_data);
    };

    let reserveTime = (isAppointment) => {
        let pro = isAppointment ? appointmentSelectedPro : blockSelectedPro;
        let loc = isAppointment ? appointmentSelectedLoc : blockSelectedLoc;
        let svc = isAppointment ? appointmentSelectedSvc : {};
        let clt = isAppointment ? appointmentSelectedClt : {};

        if (pro == "" || loc == "" || svc == "" || clt == "") {
            alert(
                "One of the required fields were left empty, please fill out."
            );
            return;
        }

        isAppointment ? setAppointmentOpened(false) : setBlockOpened(false);

        var proWorksAtLoc = false;
        for (let i = 0; i < appointmentSelectedLoc.pros.length; i++) {
            if (
                appointmentSelectedLoc.pros[i]._id == appointmentSelectedPro._id
            ) {
                proWorksAtLoc = true;
                break;
            }
        }
        if (!proWorksAtLoc) {
            alert(
                `The professional "${appointmentSelectedPro.callby_name}" does not work at location "${appointmentSelectedLoc.name}"`
            );
            return;
        }

        var svcProvidedAtLoc = false;
        for (let i = 0; i < appointmentSelectedLoc.svcs.length; i++) {
            if (
                appointmentSelectedLoc.svcs[i]._id == appointmentSelectedSvc._id
            ) {
                svcProvidedAtLoc = true;
                break;
            }
        }
        if (!svcProvidedAtLoc) {
            alert(
                `The service "${appointmentSelectedSvc.name}" is not provided at location "${appointmentSelectedLoc.name}"`
            );
            return;
        }

        let time = isAppointment ? appointmentTime : blockTime;
        let tzoffset = new Date().getTimezoneOffset() * 60000;
        var localISOTime = new Date(new Date(time) - tzoffset).toISOString();
        let appointment_object = {
            pros: [{ _id: pro._id }],
            locs: [{ _id: loc._id }],
            svcs: svc != {} ? [{ _id: svc._id }] : [],
            clts: clt != {} ? [{ _id: clt._id }] : [],
            start: localISOTime,
        };

        const call_options = {
            url: `${api_url}/appt`,
            method: "POST",
            data: appointment_object,
            headers: {
                Authorization: "Bearer " + state.loggedUser.idt,
                "content-type": "application/json",
            },
        };

        axios(call_options)
            .then((res) => {
                alert("Appointment successfully created!");
                console.log(res);
                var newAppts = shownAppointments;
                newAppts.push({
                    start: res.data.start,
                    end: res.data.end,
                    id: res.data._id,
                    title:
                        svc.name + " by " + pro.callby_name + " at " + loc.name,
                });
                setShownAppointments(newAppts);
                console.log(shownAppointments[shownAppointments.length - 1]);
            })
            .catch((err) => {
                alert("Could not create your appointment, the professional is busy at this slot.");
                console.log(err);
            });
    };

    let filterValidDatesByProfessional = (pro) => {
        var invalidDates = [];
        for (var i = 0; i < rawData.length; i++) {
            var thisPro = false;
            for (var j = 0; j < rawData[i].pros.length; j++) {
                if (rawData[i].pros[j]._id == pro._id) {
                    thisPro = true;
                    break;
                }
            }
            thisPro && invalidDates.push({
                start: new Date(rawData[i].start).toISOString(),
                end: new Date(rawData[i].end).toISOString()
            })
        };
        console.log(invalidDates);
        setInvalidDatesByProfessional(invalidDates);
    }

    return (
        <>
            <Box m={3}>
                <center>
                    <Box component="span" m={2}>
                        <Button
                            startIcon={<Add />}
                            color="primary"
                            onClick={() => setAppointmentOpened(true)}
                        >
                            Appointment
                        </Button>
                    </Box>
                    <Box component="span" m={2}>
                        <Button
                            startIcon={<Block />}
                            color="secondary"
                            onClick={() => setBlockOpened(true)}
                        >
                            Block Time
                        </Button>
                    </Box>
                    <Box component="span" m={2}>
                        <ToggleButtonGroup
                            value={selectedView}
                            exclusive
                            onChange={(ev, newValue) =>
                                setSelectedView(newValue)
                            }
                            size="small"
                        >
                            <ToggleButton value="week">Week</ToggleButton>
                            <ToggleButton value="month">Month</ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                    <br />
                    <br />
                    <Box component="span" m={3}>
                        <FormControl style={{ width: "250px" }}>
                            <Select
                                value={selectedPro}
                                onChange={(ev) =>
                                    applyAllFilters("pros", ev.target.value)
                                }
                                displayEmpty
                            >
                                <MenuItem value="All">
                                    <em>All</em>
                                </MenuItem>
                                {pros.map((pro, id) => {
                                    return (
                                        <MenuItem
                                            value={pro.callby_name}
                                            key={id}
                                        >
                                            {pro.callby_name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            <FormHelperText>
                                Filter by Professional
                            </FormHelperText>
                        </FormControl>
                    </Box>
                    <Box component="span" m={3}>
                        <FormControl style={{ width: "250px" }}>
                            <Select
                                value={selectedLoc}
                                onChange={(ev) =>
                                    applyAllFilters("locs", ev.target.value)
                                }
                                displayEmpty
                            >
                                <MenuItem value="All">
                                    <em>All</em>
                                </MenuItem>
                                {locs.map((loc, id) => {
                                    return (
                                        <MenuItem value={loc.name} key={id}>
                                            {loc.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            <FormHelperText>Filter by Location</FormHelperText>
                        </FormControl>
                    </Box>
                    <Box component="span" m={3}>
                        <FormControl style={{ width: "250px" }}>
                            <Select
                                value={selectedSvc}
                                onChange={(ev) =>
                                    applyAllFilters("svcs", ev.target.value)
                                }
                                displayEmpty
                            >
                                <MenuItem value="All">
                                    <em>All</em>
                                </MenuItem>
                                {services.map((svc, id) => {
                                    return (
                                        <MenuItem value={svc.name} key={id}>
                                            {svc.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            <FormHelperText>Filter by Service</FormHelperText>
                        </FormControl>
                    </Box>
                </center>
            </Box>

            <Eventcalendar
                theme="ios"
                themeVariant="light"
                clickToCreate={false}
                dragToCreate={false}
                dragToMove={false}
                dragToResize={false}
                data={shownAppointments}
                responsive={responsiveCalendarViews}
                onPageChange={(ev) => {
                    fetchAppointments(ev);
                }}
                onPageLoaded={(ev) => {
                    if (firstFetched) return;
                    setFirstFetched(true);
                    fetchAppointments(ev);
                }}
                onInit={() => fetchAllData()}
            />

            <Dialog
                open={appointmentOpened}
                onClose={() => setAppointmentOpened(false)}
                fullScreen={fullScreen}
                maxWidth="md"
                fullWidth={true}
            >
                <DialogTitle>New Appointment</DialogTitle>
                <DialogContent>
                    <Box m={3}>
                        <Box m={3}>
                            <FormControl
                                variant="outlined"
                                style={{ width: "100%" }}
                            >
                                <InputLabel id="apt-service-label">
                                    Select Service
                                </InputLabel>
                                <Select
                                    labelId="apt-service-label"
                                    id="apt-service"
                                    value={appointmentSelectedSvc}
                                    onChange={(ev) =>
                                        setAppointmentSelectedSvc(
                                            ev.target.value
                                        )
                                    }
                                    label="Select Service"
                                >
                                    {services.map((svc, id) => {
                                        return (
                                            <MenuItem value={svc} key={id}>
                                                {svc.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box m={3}>
                            <FormControl
                                variant="outlined"
                                style={{ width: "100%" }}
                            >
                                <InputLabel id="apt-location-label">
                                    Select Location
                                </InputLabel>
                                <Select
                                    labelId="apt-location-label"
                                    id="apt-location"
                                    value={appointmentSelectedLoc}
                                    onChange={(ev) =>
                                        setAppointmentSelectedLoc(
                                            ev.target.value
                                        )
                                    }
                                    label="Select Location"
                                >
                                    {locs.map((loc, id) => {
                                        return (
                                            <MenuItem value={loc} key={id}>
                                                {loc.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box m={3}>
                            <FormControl
                                variant="outlined"
                                style={{ width: "100%" }}
                            >
                                <InputLabel id="apt-professional-label">
                                    Select Professional
                                </InputLabel>
                                <Select
                                    labelId="apt-professional-label"
                                    id="apt-professional"
                                    value={appointmentSelectedPro}
                                    onChange={(ev) => {
                                        setAppointmentSelectedPro(
                                            ev.target.value
                                        );
                                        filterValidDatesByProfessional(
                                            ev.target.value
                                        );
                                    }}
                                    label="Select Professional"
                                >
                                    {pros.map((pro, id) => {
                                        return (
                                            <MenuItem value={pro} key={id}>
                                                {pro.callby_name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box m={3}>
                            <FormControl
                                variant="outlined"
                                style={{ width: "100%" }}
                            >
                                <InputLabel id="apt-client-label">
                                    Select Client
                                </InputLabel>
                                <Select
                                    labelId="apt-client-label"
                                    id="apt-client"
                                    value={appointmentSelectedClt}
                                    onChange={(ev) => {
                                        setAppointmentSelectedClt(
                                            ev.target.value
                                        );
                                    }}
                                    label="Select Client"
                                >
                                    {clients.map((clt, id) => {
                                        return (
                                            <MenuItem value={clt} key={id}>
                                                {clt.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Box>

                        <Box m={3}>
                            <Datepicker
                                controls={["date", "time"]}
                                stepMinute={15}
                                touchUi={true}
                                theme="ios"
                                themeVariant="light"
                                value={appointmentTime}
                                onChange={(ev) => {
                                    setAppointmentTime(ev.value);
                                }}
                                returnFormat="iso8601"
                                min={new Date().toISOString()}
                                placeholder="Select data and time"
                                invalid={invalidDatesByProfessional}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setAppointmentOpened(false)}
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button onClick={() => reserveTime(true)} color="primary">
                        Add Appointment
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={blockOpened}
                onClose={() => setBlockOpened(false)}
                fullScreen={fullScreen}
                maxWidth="md"
                fullWidth={true}
            >
                <DialogTitle>Block Time</DialogTitle>
                <DialogContent>
                    <Box m={3}>
                        <Box m={3}>
                            <FormControl
                                variant="outlined"
                                style={{ width: "100%" }}
                            >
                                <InputLabel id="blk-location-label">
                                    Select Location
                                </InputLabel>
                                <Select
                                    labelId="blk-location-label"
                                    id="blk-location"
                                    value={blockSelectedLoc}
                                    onChange={(ev) =>
                                        setBlockSelectedLoc(ev.target.value)
                                    }
                                    label="Select Location"
                                >
                                    {locs.map((loc, id) => {
                                        return (
                                            <MenuItem value={loc} key={id}>
                                                {loc.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box m={3}>
                            <FormControl
                                variant="outlined"
                                style={{ width: "100%" }}
                            >
                                <InputLabel id="blk-professional-label">
                                    Select Professional
                                </InputLabel>
                                <Select
                                    labelId="blk-professional-label"
                                    id="blk-professional"
                                    value={blockSelectedPro}
                                    onChange={(ev) => {
                                        setBlockSelectedPro(ev.target.value);
                                        filterValidDatesByProfessional(
                                            ev.target.value
                                        );
                                    }}
                                    label="Select Professional"
                                >
                                    {pros.map((pro, id) => {
                                        return (
                                            <MenuItem value={pro} key={id}>
                                                {pro.callby_name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box m={3}>
                            <Datepicker
                                controls={["date", "time"]}
                                stepMinute={15}
                                touchUi={true}
                                theme="ios"
                                themeVariant="light"
                                value={blockTime}
                                onChange={(ev) => {
                                    setBlockTime(ev.value);
                                }}
                                returnFormat="iso8601"
                                min={new Date().toISOString()}
                                placeholder="Select data and time"
                                invalid={invalidDatesByProfessional}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setBlockOpened(false)}
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button onClick={() => reserveTime(false)} color="primary">
                        Block Time
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
