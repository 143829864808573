import React from 'react';
import BaseLayout from "./components/BaseLayout";

function App() {
  return (
    <BaseLayout/>
  );
}

export default App;
