import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../hooks/globalContext";
import firebaseConfig from "../../secrets/firebase.config.json";
import firebase from "firebase/app";
import axios from "axios";
import "firebaseui/dist/firebaseui.css";
var firebaseui = require("firebaseui");

export const Login = () => {
    const [state, dispatch] = useContext(GlobalContext);
    const dev_hosts = ["local.piebooking.dev"];
    var api_host = window.location.hostname;
    if (api_host.startsWith('react-')) api_host = api_host.slice(6);
    const is_dev = dev_hosts.filter((x) => api_host.includes(x)).length > 0;
    const api_port = is_dev ? ":5001" : "";
    const api_proto = window.location.protocol;
    const api_url = `${api_proto}//api.${api_host}${api_port}/v3`;

    useEffect(() => {
        firebase.initializeApp(firebaseConfig);
        var auth = firebase.auth();
        var ui = new firebaseui.auth.AuthUI(auth);
        ui.start("#firebaseui-auth-container", {
            signInOptions: [
                {
                    provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                    requireDisplayName: false,
                    signInMethod:
                        firebase.auth.EmailAuthProvider
                            .EMAIL_LINK_SIGN_IN_METHOD,
                },
                firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                firebase.auth.TwitterAuthProvider.PROVIDER_ID,
                firebase.auth.GithubAuthProvider.PROVIDER_ID,
            ],
        });
        auth.onAuthStateChanged(async (user) => {
            console.log("Logged in as:");
            console.log(user);
            if (user == null) return;
            document.getElementById("firebaseui-auth-container").style.display =
                "none";
            var idt = await user.getIdToken(true);
            console.log(`got idt: ${idt}`);

            const userOptions = {
                url: `${api_url}/ping`,
                method: "GET",
                headers: { Authorization: "Bearer " + idt },
            };

            axios(userOptions)
                .then((response) => {
                    console.log("fetched user info...");
                    console.log(response.data);
                    dispatch({
                        type: "setLoggedUser",
                        payload: {
                            idt: idt,
                            uid: response.data.user._id,
                        },
                    });
                })
                .catch((err) => console.log(err));
        });
    }, []);
    return <div id="firebaseui-auth-container"></div>;
};
