import {
  AssignmentInd,
  CalendarToday,
  ExitToApp,
  FormatListBulleted,
  Group,
  Map,
  PermContactCalendar,
  Settings,
  Storefront,
  VpnKey,
} from "@material-ui/icons";
import { Subscription } from "../components/views/Subscription";
import { Schedule } from "../components/views/Schedule";
import { WaitingList } from "../components/views/WaitingList";
import { ClientManagement } from "../components/views/ClientManagement";
import { TeamManagement } from "../components/views/TeamManagement";
import { ServiceManagement } from "../components/views/ServiceManagement";
import { LocationManagement } from "../components/views/LocationManagement";
import { AccountSettings } from "../components/views/AccountSettings";
import { Login } from "../components/views/Login";

const navData = [
  {
    text: "Login",
    content: <Login />,
    icon: <VpnKey />,
    al: "Guest",
  },
  {
    text: "Subscription",
    content: <Subscription />,
    icon: <Storefront />,
    al: "Manager",
  },
  {
    text: "Schedule",
    content: <Schedule />,
    icon: <CalendarToday />,
    al: "Manager",
  },
  {
    text: "Waiting List",
    content: <WaitingList />,
    icon: <FormatListBulleted />,
    al: "Manager",
  },
  {
    text: "Client Management",
    content: <ClientManagement />,
    icon: <PermContactCalendar />,
    al: "Manager",
  },
  {
    text: "Team Management",
    content: <TeamManagement />,
    icon: <Group />,
    al: "Manager",
  },
  {
    text: "Service Management",
    content: <ServiceManagement />,
    icon: <AssignmentInd />,
    al: "Manager",
  },
  {
    text: "Location Management",
    content: <LocationManagement />,
    icon: <Map />,
    al: "Manager",
  },
  {
    text: "Account & Settings",
    content: <AccountSettings />,
    icon: <Settings />,
    al: "Manager",
  },
  {
    text: "Logout",
    content: <div>Logging out...</div>,
    icon: <ExitToApp />,
    al: "Manager",
  },
];

export default navData;
