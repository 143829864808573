export const initialState = {
    currentPage: "",
    loggedUser: {
        uid: "",
        idt: "",
    },
};

export const reducer = (state, action) => {
    switch (action.type) {
        case "setCurrentPage":
            return { ...state, currentPage: action.payload };
        case "setLoggedUser":
            return { ...state, loggedUser: action.payload };
        default:
            return state;
    }
};
